<template>
  <div>
    <div class="bet-tool-menu">
      <CustomHeader
        v-if="header"
        :color="colorsConfig.color"
        :background="colorsConfig.headerBackground"
        :borderColor="colorsConfig.header2Background"
        :icon="colorsConfig.logo"
        text="Herramienta para apostadores"
      />
      <div class="bet-tool-menu__view">
        <div class="bet-tool-menu__view__button" @click="clickBackMenu" v-if="selectedSection !== 'Menu'">
          <img src="/images/icn-back.png" alt="back" />
          Volver
        </div>
        <KeepAlive>
          <Component
            :is="selectedComponent"
            :total-bets="totalBets"
            :total-bets-right-bet="totalBetsRightBet"
            :total-bets-by-risk="totalBetsByRisk"
            :team-id="colorsConfig.team_id"
            :is-premium-user="isPremiumUser"
            :seasons="seasons"
            @premium-disabled-clicked="$emit('premium-disabled-clicked', $event)"
            @menuChange="menuChange"
            @data-fetched="$emit('filters-fetched')"
          />
        </KeepAlive>
      </div>
    </div>
  </div>
</template>
<script>
import {
  clickQueEsGolStats,
  clickComoFunciona,
  personalizedBetParlay,
  personalizedBetResult,
  personalizedTips,
} from '@/utils/analytics';
import axios from 'axios';
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'BetToolMenu',
  components: {
    CustomHeader: () => import('@/components/Elements/CustomizableHeader'),
    Menu: () => import('@/components/Sections/BetTool/Menu'),
    Parlay: () => import('@/components/Sections/BetTool/Parlay'),
    RightBet: () => import('@/components/Sections/BetTool/RightBet'),
    TipGolStats: () => import('@/components/Sections/BetTool/TipGolStats'),
  },
  props: {
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    colorsConfig: {
      type: Object,
      required: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    /**
     * Seasons objects to fetch games
     * {Array<{id: number, type: number}>} seasons
     */
    seasons: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    colorsConfig: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (Object.keys(newValue).length) {
          this.fillParlayBets();
        }
      },
    },
  },
  data() {
    return {
      selectedSection: '',
      selectedComponent: '',
      totalBets: [],
      totalBetsRightBet: [],
      totalBetsByRisk: { 1: [], 2: [], 3: [] },
      parlayBets: [],
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    ...mapMutations('general', ['setVideoModalVisibility', 'setVideoSrc']),
    isPremium() {
      return !!this.user.premiumAccount;
    },
    clickBackMenu() {
      this.selectedSection = 'Menu';
      this.selectedComponent = 'Menu';
    },
    menuChange(section) {
      let obj = {
        RightBet: () => personalizedBetResult({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats }),
        Parlay: () => personalizedBetParlay({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats }),
        TipGolStats: () => personalizedTips({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats }),
      };
      if (section.name == 'Parlay') {
        this.fillParlayBets();
      }
      obj[section.component]();
      this.selectedSection = section.name;
      this.selectedComponent = section.component;
    },

    async fetchAllBets() {
      try {
        const nationalTournaments = [1, 1001, 1071];
        const {
          data: { tournaments },
        } = await axios.get('https://3ejrkiihni.execute-api.us-west-2.amazonaws.com/prod/parlays');
        // const { tournaments } = temporalBets;
        return tournaments.reduce((accumulator, tournament) => {
          tournament.bets.forEach(bet => {
            if (nationalTournaments.includes(bet.tournament_id)) {
              bet.provisionalTeamLogo = `https://az755631.vo.msecnd.net/teams-80`;
            }
            bet.tournament_name = tournament.tournament_name;
            bet.home_team_id = Number(bet.home_team_id);
            bet.away_team_id = Number(bet.away_team_id);
            accumulator.push(bet);
          });
          return accumulator;
        }, []);
      } catch (e) {
        throw new Error(e);
      }
    },
    async fillParlayBets() {
      try {
        const now = new Date().valueOf();
        const dayInMilliseconds = 1000 * 60 * 60 * 24;
        const maximumDaysAvailable = 100 * dayInMilliseconds + now;
        const allBets = await this.fetchAllBets();
        this.totalBetsRightBet = [...allBets];
        this.totalBets = allBets.filter(bet => {
          const betDate = new Date(bet.date).valueOf();
          return bet.bet_risk && betDate > now && betDate <= maximumDaysAvailable;
        });
        this.filterParlayBets();
      } catch (e) {
        throw new Error(e);
      }
    },
    filterParlayBets() {
      const gamesId = new Set();
      this.totalBets.forEach(bet => {
        bet.bet_suggested.teams = [
          Number(bet.bet_suggested.home_team_logo.replace(/\.png/g, '') || 0),
          Number(bet.bet_suggested.away_team_logo.replace(/\.png/g, '') || 0),
        ];
        bet.bet_suggested.home_team_id = Number(bet.bet_suggested.home_team_logo.replace(/\.png/g, '') || 0);
        bet.bet_suggested.away_team_id = Number(bet.bet_suggested.away_team_logo.replace(/\.png/g, '') || 0);
        bet.losing_team = !bet.bet_suggested.teams.includes(bet.home_team_id)
          ? bet.home_team_id
          : !bet.bet_suggested.teams.includes(bet.away_team_id)
          ? bet.away_team_id
          : null;
        gamesId.add(bet.game_id);
      });
      this.totalBetsByRisk = this.totalBets.reduce(
        (accumulator, bet) => {
          accumulator[bet.bet_risk].push(bet);
          return accumulator;
        },
        { 1: [], 2: [], 3: [] },
      );
      Object.values(this.totalBetsByRisk).forEach(bets => {
        bets.sort((a, b) => b.difference_probability - a.difference_probability);
      });
    },
    onClickQueEsGolStats() {
      clickQueEsGolStats(this.getPremiumAccount, this.getIsGolstats);
      this.setVideoModalVisibility(true);
    },
    onClickComoFunciona() {
      clickComoFunciona(this.getPremiumAccount, this.getIsGolstats);
      this.showVideo();
    },
    showVideo() {
      this.setVideoSrc('https://az740894.vo.msecnd.net/golstats-bets/tips.mp4');
      this.setVideoModalVisibility(true);
    },
  },
  mounted() {
    this.selectedSection = 'Menu';
    this.selectedComponent = 'Menu';
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.bet-tool-menu {
  margin-top: 30px;
  &__view {
    margin: 0 auto;
    margin-bottom: 30px;
    position: relative;
    &__button {
      width: 100px;
      position: relative;
      height: 30px;
      margin-left: 7px;
      color: white;
      text-align: center;
      line-height: 30px;
      background: #383a3c;
      text-align: center;
      border-radius: 30px;
      z-index: 2;
      margin-top: 16px;
      cursor: pointer;
      @media screen and (max-width: 468px) {
        position: relative;
      }
      img {
        width: 24px;
        float: left;
        margin-left: 10px;
        margin-top: 2px;
      }
      &__text {
        float: left;
        margin-left: 2px;
        font-family: 'Circular-Std-Bold';
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.04px;
        color: #fff;
        margin-top: 7px;
      }
    }
    &__buttom:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
